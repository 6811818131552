import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let TeamContent = [
    
    {
        images: 'photo-Christos-Roumeliotis',
        title: 'Christos Roumeliotis',
        designation: 'Co-Founder',
        socialNetwork: [
            {
                icon: <FaTwitter />,
                url: 'https://twitter.com/ChRoumeliotis?t=4Ry7_g7799J4SJWjaVjFrA&s=07&fbclid=IwAR0_s_Jhkg6TW2a-f_hh3kFBeTHDKtYtPq7gWi9yOevDEiDXeIrZIoqalUA'
            },
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/croumeliotis/'
            },
        ]
    },
    {
        images: 'photo-Ourania-Konstantinidou',
        title: 'Ourania Konstantinidou',
        designation: 'Co-Founder',
        socialNetwork: [
            
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/ourania-konstantinidou'
            },
        ]
    },
    {
        images: 'photo-Vasilis-Balafas',
        title: 'Vasilis Balafas',
        designation: 'Co-Founder',
        socialNetwork: [
            
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/mpvasilis/ '
            },
           
        ]
    },
    {
        images: 'Tasos-Garinis-photo',
        title: 'Tasos Garinis',
        designation: 'Co-Founder',
        socialNetwork: [
           
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/tgarinis/'
            },
        ]
    },

];


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/${value.images}.jpg`} alt="Blog Images"  />
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a  target="_blank" href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;