import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";


const PortfolioList = [
    {
        image: 'powertrade',
        category: 'Software',
        title: ' PowerTrade',
        description: 'PowerTrade is Gridustry\'s first decentralized distributed electricity platform for the purchase and management of energy between producers - consumers and aims to develop a distributed market for renewable energy with a focus on community energy systems and new renewable energy business. Essentially, through PowerTrade we aim to change the market by providing green energy in different parts of the world, thus eliminating the supply and demand problem of renewable energy. Powertrade decentralises energy management and aims to create energy autonomous "microgrids", where the population will meet its energy needs based on the energy produced by the members of the microgrid. Certification of green energy use will be done through the issuance of green PPAs, reducing carbon emissions (CO2) and aiming at more efficient use of green energy through renewable energy sources.'
    },
   
]

class Portfolio extends Component{
    render(){
        let title = 'Our Solutions',
        description = 'For Gridustry, enhancing digital transformation and energy transition is an ongoing goal.With the use of new technologies, with continuous research in the field of technology and our own personal work we intend to change the data of the society and our services to be the transition to sustainable development and a greener environment';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 className="title">{value.title}</h4>
                                            <h5 className="description">{value.description}</h5>
                                            {/* <div className="portfolio-button">
                                                <a className="rn-btn" href="/portfolio-details">Case Study</a>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <Link className="link-overlay" to="/portfolio-details"></Link> */}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;