import React from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let data = [
    {
        images: '01',
        images: '/assets/images/team/photo-Vasilis-Balafas.jpg',
        title: 'Vasilis Balafas',
        designation: 'Co-Founder',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/vasilis.mpalafas'
            },
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/mpvasilis/'
            },
           
        ]
    },
    {
        images: '02',
        title: 'Tasos Garinis',
        designation: 'Co-Founder',

                                        
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/Tas0s.gr/'
            },
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/tgarinis/'
            },
        ]
    },
    {
        images: '03',
        title: 'Christos Roumeliotis',
        designation: 'Co-Founder',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/christos.roumeliotis.drcr'
            },
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/croumeliotis/'
            },
           
        ]
    },
    
    {
        images: '06',
        title: 'Christos Katsiagonis',
        designation: 'Software-Developer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/chris.katsiagonis'
            },
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/chris-kats-0b6630222/'
            },
           
        ]
    },
    // {
    //     images: '06',
    //     title: 'Jone Due',
    //     designation: 'Sr. Web Developer',
    //     socialNetwork: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaTwitter />,
    //             url: '#'
    //         },
    //     ]
    // },
    // {
    //     images: '05',
    //     title: 'Fatima Asrafi',
    //     designation: 'Front-end Engineer',
    //     socialNetwork: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: '#'
    //         },
    //     ]
    // },
    // {
    //     images: '06',
    //     title: 'Fatima Amar',
    //     designation: 'Sr. Graphic Designer',
    //     socialNetwork: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaTwitter />,
    //             url: '#'
    //         },
    //     ]
    // },
    // {
    //     images: '07',
    //     title: 'Fatima Amar',
    //     designation: 'Sr. Graphic Designer',
    //     socialNetwork: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaTwitter />,
    //             url: '#'
    //         },
    //     ]
    // },
    // {
    //     images: '11',
    //     title: 'John Dou',
    //     designation: 'Sr. Graphic Designer',
    //     socialNetwork: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaTwitter />,
    //             url: '#'
    //         },
    //     ]
    // },
    // {
    //     images: '09',
    //     title: 'John Dou',
    //     designation: 'Sr. Graphic Designer',
    //     socialNetwork: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaTwitter />,
    //             url: '#'
    //         },
    //     ]
    // },
    // {
    //     images: '11',
    //     title: 'John Dou',
    //     designation: 'Sr. Graphic Designer',
    //     socialNetwork: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaTwitter />,
    //             url: '#'
    //         },
    //     ]
    // },



];

export default data;