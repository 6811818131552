import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import SliderTwo from "../component/slider/SliderTwo";
import SliderThree from "../component/slider/SliderThree";
import TeamOne from "../elements/team/TeamOne";

import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import Team from "../elements/Team";
import Team2 from "../elements/Team2";
import Particles from 'react-particles-js';
import Slider from "react-slick";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'GRIDUSTRY',
        description: 'Connecting Producers to Consumers',
        buttonText: 'Contact Us',
        buttonLink: 'mailto:info@gridustry.com'
    }
]

class MainDemo extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <div className="active-dark"> 
                <Helmet pageTitle="Gridustry |  Decentralized Energy Solutions" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                 <div className="slider-activation slider-creative-agency with-particles" id="home">
                    <div className="frame-layout__particles">
                        <Particles
                            params={{
                                "particles": {
                                    "number": {
                                        "value": 100
                                    },
                                    "size": {
                                        "value": 6
                                    }
                                },
                                "interactivity": {
                                    "events": {
                                        "onhover": {
                                            "enable": true,
                                            "mode": "repulse"
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="bg_image ">
                            {SlideList.map((value , index) => (
                                <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                </div>
                {/* End Slider Area   */}


                <div className="about-area about-position-top pb--120 bg_color--1 ">
                <div id="about">   
                    <About />
                    </div>
                </div>

                <div className="service-area ptb--80  bg_image bg_image--3">
                   <div className="container">
                   <div id="service"> 
                        <ServiceTwo />
                   </div>
                   </div>
                </div>
                

                <div className="rn-team-wrapper  bg_color--1">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                    <div id="team"> 
                                        <h2><br></br>Our Team</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <Team column="col-lg-3" />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="rn-team2-wrapper  bg_color--1">
                    <div className="rn-team2-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <Team2 column="col-lg-3" />
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation" style={{background:"#101010"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="section-title text-center mb--30">
                                        <h2>Our Partners</h2>
                                    </div>
                                <div id="partners"> 
                                <BrandTwo />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                <div id="contact">   
                    <contact />
                    </div>
                
                </div>
                End Back To Top
                
                <Footer />
                
            </div>
        )
    }
}
export default MainDemo;