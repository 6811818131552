import React, { Component } from "react";
import { FiCast , FiLayers , FiNavigation} from "react-icons/fi";
import { RiExchangeFundsLine} from "react-icons/ri";
import { AiOutlineSafetyCertificate} from "react-icons/ai";
import { FaFileContract} from "react-icons/fa";

const ServiceList = [
    {
        icon: <RiExchangeFundsLine />,
        title: 'PowerTrade',
        description: 'PowerTrade is an energy trading solution based on blockchain technology and zero carbon energy goals aiming to develop a distributed market for renewable energy. '
    },
    {
        icon: <FaFileContract />,
        title: 'PowerPPA',
        description: 'PowerPPA connects buyers with renewable producers through PPAs which are the most significant approach to renewable energy investments today, including certification of 24/7 energy procurement based on energy frameworks.'
    },
    {
        icon: <AiOutlineSafetyCertificate />,
        title: 'PoweREC',
        description: 'PoweREC is a tool that certifies 24/7 energy procurement. Green certificates are the only way for consumers to actively choose their energy source and send a message to the market about their energy preferences.'
    }
   
]

class ServiceTwo extends Component{
    render(){
        
        let description =  'PowerBlock is a digital, interoperable suite for energy trading, 24/7 power certification, and Power Purchase Agreements (PPAs). Includes 3 easy-integrated components:';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-13 col-13"> 
                     
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">PowerBlock&#174; Suite</h2>
                            <p>{description}</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-13 col-13 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-4 col-md-4 col-sm-4 col-12" key={i}>
                                   
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                 
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
