import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let TeamContent = [
    {
        images: 'photo-Antreas-Zafeiris',
        title: 'Antreas Zafeiris',
        designation: 'Financial Consulting',
        socialNetwork: [
           
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/andreas-nikolaos-zafeiris-814420222/'
            },
        ]
    },
   
    {
        images: 'photo-Giorgos-Tsiliopoulos',
        title: 'Giorgos Tsiliopoulos',
        designation: 'Machine Learning Engineer',
        socialNetwork: [
            
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/george-tsiliopoulos-80991819b/'
            },
           
        ]
    },
    {
        images: 'photo-Marios-Karamintzios',
        title: 'Marios Karamintzios',
        designation: 'Blockchain Developer',
        socialNetwork: [
            
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/marios-karamintzios/'
            },
        ]
    },
  
    
    {
        images: 'photo-Kostas-Kolonias',
        title: 'Konstantinos Kolonias',
        designation: 'Software Developer',
        socialNetwork: [
            
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/kostas-kolonias-252529166/'
            },
           
           
        ]
    },
   
];


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/${value.images}.jpg`} alt="Blog Images" />
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a  target="_blank" href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;