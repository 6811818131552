import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About us',
        description = "Innovation Bee' s Gridustry is a platform for decentralized energy solutions. We are accelerating a new electricity market system and empowering the transition to green energy through our digital solutions, connecting consumers with renewable energy producers around the world. To achieve this, we apply new artificial intelligence techniques and decentralized technologies while providing IT services and expanding our position in other sectors.  We provide powerful solutions to manage the green energy certification processes among all the variable stakeholders. We have created a breakthrough digital bridge that handles all the in-between steps from energy production to the creation of the digital green energy certification.";
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                        
                            <div className="col-lg-11 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title" style={{marginTop:40}}>
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Our Vision</h3>
                                                <p>Our vision is to change the way consumers and producers are approaching energy consumption. Our company emphasizes state-of-the-art technologies and strives to create easy solutions that any corporate buyer can adopt. Our work includes a certification of 24/7 energy procurement based on existing frameworks: Guarantees of Origin (GOs), Renewable Energy Certificates (RECs), International - Renewable Energy Certificates (I-RECs), etc.</p>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">What we do</h3>
                                                <p>Gridustry's main goal and ambition is to transform the energy sector and promote renewable energy sources for a greener future. Gridustry focuses on the creation and development of smart applications using state-of-the-art technologies such as blockchain and artificial intelligence.</p>
                                                                                            
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    
               
            </React.Fragment>
        )
    }
}
export default About;