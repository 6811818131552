import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/client/protergia.png" style={{width:300, maxWidth: 400, maxHeight: 500}} alt="Protergia"/>
                    </li>
                    <li>
                        <img src="/assets/images/client/mytilineos.png" style={{width:300, maxWidth: 400, maxHeight: 500}} alt="mytilineos"/>
                    </li>
                    <li>
                        <img src="/assets/images/client/hbh.png" style={{width:300, maxWidth: 400, maxHeight: 500}} alt="hbh"/>
                    </li>
                    <li>
                        <img src="/assets/images/client/MITEF.png" style={{'height':'65%' , width:300, maxWidth: 400, maxHeight: 400, filter: "invert(100%)"}} alt="MITEF"/>
                    </li>
                    <li>
                        <img src="/assets/images/client/oneup.png" style={{width:300, maxWidth: 400, maxHeight: 500 }} alt="oneup"/>
                    </li>
                    <li>
                        <img src="/assets/images/client/european_universe.png" style={{width:300, maxWidth: 400, maxHeight: 500 }} alt=" european_universe"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;